export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        // Aanbod
        $('.landing__references__slider').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
        });

        $('.slider__content').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            asNavFor: '.slider__nav',
        });

        $('.slider__nav').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 3,
            focusOnSelect: true,
            asNavFor: '.slider__content',
        });

        // Temp fix - header.js not working?
        // $('.header__menu__btn').on('click',  function () {
        //     $('body').toggleClass('header__menu__open')
        // })
        // $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
        // $('.menu-item-touch').on('click', function () {
        //     $(this).parent('.menu-item').toggleClass('open')
        // })
        //
        // if ($(window).width() > 767) {
        //     $(window).scroll(function () {
        //         let scroll = $(window).scrollTop();
        //         if (scroll > 120) {
        //             $('.header').addClass('header--fix');
        //         } else {
        //             $('.header').removeClass('header--fix');
        //         }
        //     });
        // }
        //
        // $('.header__search__toggle').on('click', function () {
        //     $('.header__search').toggleClass('open')
        //     $('.header__search__input input').focus()
        // });

        var aanbod = $('.aanbod__container .aanbod__item').length;
        var counter = 6;
        $('.aanbod__container .aanbod__item').slice(6).hide();

        $('.show-more-aanbod').on('click', function() {
            counter = counter + 3;
            $('.aanbod__container .aanbod__item').slice(0, counter).slideDown(500);
            if(counter >= aanbod) {
                $(this).hide();
            }
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
